<template>
  <div class="warp">
    <common-header></common-header>
    <div class="selection-box">
      <div class="item" @click="openPicker()">
        <span>{{startTime}}</span>
        <i class="icon-black-arrow"></i>
      </div>
      <div class="item" @click="commodityType = true">
        <span>{{commodityObj ? commodityObj.name : ''}}</span>
        <i class="icon-black-arrow"></i>
      </div>
    </div>
    <table class="table" v-if="lists.length > 0"
      v-infinite-scroll="loadMore"
      infinite-scroll-disabled="loading"
      infinite-scroll-immediate-check="false"
      infinite-scroll-distance="10">
      <tr>
        <th>时间</th>
        <th>类型</th>
        <!-- <th>订单</th> -->
        <th>金额</th>
      </tr>
      <tr v-for="(item, index) in lists" :key="index">
        <td>{{(item.createTime).substring(8,10)}}号</td>
        <td>{{item.description}}</td>
        <!-- <td>{{item.amount}}</td> -->
        <td>{{item.direction ? '-' : (item.amount > 0 ? '+' : '')}}{{item.amount}}</td>
      </tr>
    </table>

    <no-data v-if="nodata" style="margin-top:1rem"></no-data>
    
    <mt-datetime-picker
      ref="picker"
      type="date"
      year-format="{value} 年"
      month-format="{value} 月"
      :endDate="endDate"
        @confirm="handleConfirm"
      v-model="timeStartValue">
    </mt-datetime-picker>

    <mt-popup class="w100" v-model="commodityType" position="bottom">
      <div class="select-popup-btn fix">
        <span class="btn fl" @click="commodityType = false">取消</span>
        <span class="btn fr" @click="modifyCommodity">确定</span>
      </div>
      <mt-picker
        :slots="commoditySlots"
        @change="commodityChange"
        value-key="name"
      ></mt-picker>
    </mt-popup>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { DateFormat } from '@/utils/common';
export default {
  data() {
    return {
      commodityType: false,
      nodata: false,
      loading: false,
      commodityObj: '',
      commoditySlots: [ 
        {
          flex: 1,
          values: [
            {
              name: '多退少补',
              id: 7,
            },
            {
              name: '商品购买',
              id: 8,
            },
            {
              name: '退押金',
              id: 9,
            },
          ],
          className: "commodity-slots-class",
          textAlign: "center"
        }
      ],
      startTime: (DateFormat(new Date())).substring(0,7),
      endDate: new Date(),
      timeStartValue: new Date(),
      rightTxt: '',
      lists: [], 
      pages: 1,
      pageNum: 1,
      nomore: false,
    }
  },
  mounted() {
    this.init({
      srcType: this.commodityObj.id,
      queryDate: this.startTime
    })
  },
  methods: {
    loadMore() {
      this.loading = true;
      let pageNum = this.pageNum + 1;
      if (pageNum > this.pages) {
        this.nomore = true;
      } else {
        this.init({
          srcType: this.commodityObj.id,
          queryDate: this.startTime,
          pageNum: pageNum,
          add: 'add',
        });
      }
    },
    init(obj) {
      this.getTransactionList({
        srcType: obj.srcType,
        queryDate: obj.queryDate,
        amountType: 1,
        balanceType: 1,
        pageNum: obj.pageNum || 1,
      }).then((res)=> {
        if (obj.add) {
          this.lists = this.lists.concat(res.data.list);
          this.pageNum = res.data.pageNum;
        } else {
          this.lists = res.data.list;
          this.pages = res.data.pages;
        }
        this.nodata = this.lists.length > 0 ? false : true;
      })
    },
    ...mapActions('account', [
      'getTransactionList',
    ]),
    openPicker(i) {
      this.$refs.picker.open();
      let a = document.getElementsByClassName('picker-slot');
      for (let i=0;i<a.length; i++) {
        if (i == 2) {
          a[i].style.display = 'none';
        }
      }
    },
    commodityChange(picker, values) {
      this.commodityObj = values[0] ? values[0] : '';
    },
    modifyCommodity() {
      this.commodityType = false;
      this.init({
        srcType: this.commodityObj.id,
        queryDate: this.startTime
      })
    },
    handleConfirm(e) {
      this.startTime = (DateFormat(e)).substring(0,7);
      this.init({
        srcType: this.commodityObj.id,
        queryDate: this.startTime
      })
    },
  },
}
</script>
<style lang='less' scoped>
.table{
  width: 100%;
  margin-top: .4rem;
  text-align: center;
  th{
    font-size: .3rem;
  }
  td{
    font-size: .28rem;
  }
  td,th{
    border: 1px solid #f5f5f5;
    padding: .2rem 0;
  }
}
.selection-box{
  padding-top: .4rem;
  display: flex;
  .item{
    border: 1px solid #f5f5f5;
    padding: .2rem;
    flex: 1;
    margin-right: .2rem;
    border-radius: .15rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: .28rem;
    &:last-child{
      margin-right: 0;
    }
  }
}
</style>